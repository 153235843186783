import { Link } from "react-router-dom";
import Menu from "./Menu";
import {
  CompanyInfoKorea as CompanyInfo,
  CompanyInfoEnglish,
} from "common/CompanyInfo";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneOffice,
  faFax,
  faGlobe,
  faAngleDown,
} from "@fortawesome/pro-light-svg-icons";
import { faCopyright } from "@fortawesome/pro-regular-svg-icons";
import { faYoutube, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faMessageQuestion } from "@fortawesome/pro-duotone-svg-icons";

const Foot = ({ active, lang }) => {
  function click() {
    $(".language-modal").show();
  }

  return (
    <footer>
      <div className="container" itemType="http://schema.org/LocalBusiness">
        <nav>
          <div className="logo">
            <Link to={`/${lang}/index.html`}>
              <img
                itemProp="logo"
                src="/images/common/logo_line_3a3a3c.png"
                alt="Quad Miners logo"
              />
            </Link>
          </div>
          {Menu[lang].map((d) => (
            <div className="menu" key={d.title}>
              <h4>{d.title}</h4>
              <ul>
                {d.menu.map((a) => (
                  <li key={a.title}>
                    <Link
                      to={a.href}
                      className={`${active === a.rt ? "active" : ""}`}
                    >
                      {a.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          <div className="social-media">
            <ul>
              <li>
                <a
                  rel="noreferrer noopener"
                  href="https://www.youtube.com/channel/UCyZ_JRl7LaQ2gIb_xbNYa9w"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faYoutube} />
                  <small>YouTube</small>
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer noopener"
                  href="https://www.linkedin.com/company/quad-miners"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faLinkedin} />
                  <small>LinkedIn</small>
                </a>
              </li>
              <li>
                <Link to={`/${lang}/contact_us.html`}>
                  <FontAwesomeIcon icon={faMessageQuestion} />
                  <small>Help&nbsp;Desk</small>
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <div className="containerWrapper">
        <div className="container" itemType="http://schema.org/LocalBusiness">
          <address itemProp="address">
            {lang === "ko" ? CompanyInfo.address : CompanyInfoEnglish.address}
          </address>
          <div>
            <small>
              <FontAwesomeIcon icon={faPhoneOffice} />
              &nbsp; TEL&nbsp;
            </small>
            <a href={`tel:${CompanyInfo.tel}`} itemProp="telephone">
              {CompanyInfo.tel}
            </a>
            &nbsp;
            <small>
              <FontAwesomeIcon icon={faFax} />
              &nbsp; FAX&nbsp;
            </small>
            <span itemProp="faxNumber">{CompanyInfo.fax}</span>
          </div>
          <p className="copyright">
            Copyright <FontAwesomeIcon icon={faCopyright} />
            &nbsp;
            {new Date().getFullYear()}&nbsp;
            <span itemProp="name">Quad Miners</span>.&nbsp;
            {lang === "ko" ? "모든 권리 보유." : "All rights reserved"}
          </p>
          <div className="language" onClick={click}>
            <FontAwesomeIcon icon={faGlobe} />
            &nbsp;
            {lang === "ko" ? "한국어" : "English"}
            &nbsp;
            <FontAwesomeIcon icon={faAngleDown} style={{ color: "#1c7cd6" }} />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Foot;
