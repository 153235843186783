import Wrapper from 'common/Wrapper';
import { CompanyInfoKorea as CompanyInfo } from 'common/CompanyInfo';
import 'styles/About2.scss';
import { useEffect } from 'react';
import $ from "jquery";
import { InvestorList } from 'common/Team';

const About = () => {
  return (
    <Wrapper active="About" lang="ko">
      <div className="About2">
        <Intro />
        <Slogan />
        <Photos />
        <Outline />
        <Work />
        <Ci />
        <Investor />
        <Address />
        <Maps />
      </div>
    </Wrapper>
  )
};

const Intro = () => {
  return(
  <section className="about-intro2">
    <div className="container">
      <h2>
        People <small>+</small> Process <small>+</small> Technology
      </h2>
    </div>
  </section>
  )
}

const Slogan = () => {
  return(
    <section className="about-slogan2">
      <div className="container">
        <h2>더 나은 방향을 향해 <em>진지하게 고민</em>합니다.</h2>
      </div>
    </section>
  )
}

const Photos = () => {
  return (
    <section className="about-photos2">
        <div className="container">
          <div>
            <div><img src="/images/about/1.jpg" alt="" /></div>
            <div><img src="/images/about/2.jpg" alt="" /></div>
            <div><img src="/images/about/3.jpg" alt="" /></div>
            <div><img src="/images/about/4.jpg" alt="" /></div>
            <div><img src="/images/about/5.jpg" alt="" /></div>
            <div><img src="/images/about/6.jpg" alt="" /></div>
            <div><img src="/images/about/7.jpg" alt="" /></div>
            <div><img src="/images/about/8.jpg" alt="" /></div>
            <div><img src="/images/about/10.jpg" alt="" /></div>
            <div><img src="/images/about/11.jpg" alt="" /></div>
            <div><img src="/images/about/12.jpg" alt="" /></div>
            <div><img src="/images/about/13.jpg" alt="" /></div>
          </div>
        </div>
      </section>
  )
}

const Outline = () => {
  return(
    <section className="about-outline2">
      <div className="container">
        <p>
          <em>쿼드마이너</em>는 내부 정보 유출 등의 보안 위협을 해소하고, 사이버 보안 시장에 체계적으로 대응하고자 합니다. 국내 유일의 차세대 네트워크 보안 솔루션을 개발중에 있으며, 네트워크 이상 행위 분석, 실시간 트래픽 분석, 감사 등의 기능을 제공하여 네트워크 환경의 가시성을 제공합니다.
        </p>
      </div>
    </section>
  )
}

const Work = () => {
  return(
    <section className="about-work2">
      <div className="container">
        <dl>
          <dt>비전</dt>
          <dd>
            전세계에 쉽고 정확한 <em>사이버디펜스 솔루션</em>을 제공한다.
          </dd>
        </dl>
        <dl>
          <dt>철학</dt>
          <dd>
            우리는 <em>실천적 지혜</em>를 통해 성장할 수 있다고 믿습니다.<br/>
            우리는 이러한 믿음을 바탕으로 <em>기술자 중심의 생태계</em>와 <em>진지하게 고민하는 문화</em>를 만들고자 합니다.
          </dd>
        </dl>
        <dl>
          <dt>사업소개</dt>
          <dd>
            사이버 보안 시장은 계속 성장하고 있으며, 예방의 목적을 둔 단일보안 솔루션에서부터 로그의 수집 분석을 위한 ESM과 SIEM 시장 이후 <em>네트워크를 수집 저장 분석하는 형태</em>로 변화해 가고 있습니다. 쿼드마이너는 이러한 보안 시장의 변화에 발맞춰 <em>전체 패킷의 저장</em>과 <em>재현 가능</em>하고, 분석할 수 있는 솔루션을 개발하고 있습니다.
          </dd>
        </dl>
      </div>
    </section>
  );
}

const Ci = () => {
  return(
    <section className="about-ci2">
      <div className="container">
        <div>
          <img src="/images/common/logo_rect_white.png" alt="Quad Miners logo" />
        </div>
        <div style={{textAlign: 'center'}}>
          <div className="empx">
            CYBER DEFENSE
          </div>
        </div>
        <h4>
          쿼드마이너의 CI는 <em>개발의 Code와 연산자 Minus의 음차</em>인 동시에 <em>4개의 사업분야</em>를 의미합니다.<span className="break-line-auto" />
          삽 모양의 픽토그램은 <em>끈기와 노력</em>을 상징하고, <em>깊이 파고드는 것</em>을 의미합니다.
        </h4>
      </div>
    </section>
  )
}

const Investor = () => {
  return(
    <section className="partners-list2">
      <div className={`container more`} style={{ textAlign: 'center' }}>
        <h4>Investors</h4>
        <ul>
          {InvestorList.map((d, i)=>(<li key={i}>
            <a href={d.href} target={"_blank"} rel="noreferrer noopener">
              <img src={`/images/investor/${d.src}`}
                style={{ height: d.height }} alt={d.name} />
            </a>
          </li>))}
        </ul>
      </div>
    </section>
  );
}

const Address = () => {
  return(
    <section className="about-address2">
      <div className="container">
        <dl>
          <dt>회사명</dt>
          <dd>주식회사 쿼드마이너</dd>
        </dl>
        <dl>
          <dt>개업일</dt>
          <dd>2017년 11월 24일</dd>
        </dl>
        <dl>
          <dt>사업자 등록번호</dt>
          <dd>608-88-00869</dd>
        </dl>
        <dl>
          <dt>업태/종목</dt>
          <dd>
            서비스<br/>시스템 소프트웨어 개발 및 공급업<br/>
            <small>(하드웨어, 소프트웨어 판매 컨설팅)</small>
          </dd>
        </dl>
        <dl>
          <dt>소재지</dt>
          <dd>{CompanyInfo.address}</dd>
        </dl>
        <dl>
          <dt>전화번호</dt>
          <dd>{CompanyInfo.tel}</dd>
        </dl>
        <dl>
          <dt>FAX</dt>
          <dd>{CompanyInfo.fax}</dd>
        </dl>
      </div>
    </section>
  )
}

const Maps = () => {
  useEffect(()=>{
    $(()=>{
      // History
      // 37.504596, 127.025783 (신논현)
      // 37.516360, 127.037850 (강남구청)
      let centreCarte = new window.google.maps.LatLng(37.4996357,127.034346);
      let map = new window.google.maps.Map(document.getElementById('Map'), {
        center: centreCarte,
        zoom: 17,
        zoomControlOptions: {
          style: window.google.maps.ZoomControlStyle.SMALL,
          position: window.google.maps.ControlPosition.RIGHT_BOTTOM
        },
        panControl: false,
        scrollwheel: false,
        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
        mapTypeControl: false
      });
      new window.google.maps.Marker({
        position: centreCarte,
        map: map,
        title: 'Quad Miners',
        label: {
          // color: '#00aaff',
          fontWeight: 'bold',
          fontSize: '20px',
          text: 'Quad Miners',
          className: 'marker-position',
        }
      });
    });
  }, []);

  return (
    <section className="about-map2">
      <div id="Map" />
      <div className="help-text">
        주차 공간이 없어 주차하기 어렵습니다. 너른 양해를 부탁드립니다.
      </div>
    </section>
  )
}

export default About;