import Wrapper from 'common/Wrapper';
import 'styles/History2.scss';
import { useState } from 'react';
import $ from "jquery";
import React from 'react';
import * as ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/pro-regular-svg-icons';
import { faNewspaper } from '@fortawesome/pro-duotone-svg-icons';

const History = () => {
  const [show, setShow] = useState(false);
  function click() {
    setShow(true);
  }

  return (
    <Wrapper active="History" lang="ko">
      <div className="history2-wrapper">
        <Intro />
        <div>
          <CyberDSA2024 />
          <WenyoutechMou />
          <DefenseSecurityConference19 />
          <JinnHyunAISperaMou />
          <KGlobal2023 />
          <InnovationIcon />
          <Gptw2024 />
          <InsungDigital />
          <InnovationAward />
          <SeriesB />
          {!show && <ShowAll click={click} />}
        </div>

        {show && <div>
          <PiolinkZeroTrust />
          <Gartner4 />
          <Piolink />
          <Gptw2023 />
          <ICTExpoInJapan />
          <BabyUnicorn />
          <Forbes100 />
          <Won35uskorea />
          <Gptw />
          <SeriesA />
          <Gartner3 />
          <IglooMou />
          <Kodit2021 />
          <Dupex2021 />
          <Top10CloudSolution />
          <Company1000 />
          <FirstKistiCouncil />
          <NusEnterprise />
          <Gartner />
          <Sv2020 />
          <TopTechCompany2020 />
          <MoveSunghong />
          <ItExpoBusan2019 />
          <PublicSolutionMarket />
          <NationalDefense />
          <LeenosPartnerDay />
          <UnipointPartnerDay />
          <SnaPartnerDay />
          <ReshapeRoadShow2019 />
          <Nest5th />
          <SkinfosecDistributor />
          <Top10CyberSecurity />
          <G2b />
          <PrivacyGlobalEdge2019 />
          <ReshapeSingapore />
          <BattleDemo />
          <PartnerDay />
          <GsCertificate />
          <Dibs />
          <ReshapeTokyo />
          <Rise />
          <Sosv />
          <Cpo />
          <Ciociso />
          <SelectionKstartup />
          <Start />
        </div>}

        <div id="Movie"></div>
      </div>
    </Wrapper>
  )
}

const Intro = () => {
  return (
    <section className="history-intro2">
      <div className="container">
        <h2>사람과 기술을 잇고, 미래를 개척해나갑니다.</h2>
      </div>
    </section>
  )
}

const Start = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2017.11</h4>
        <div className="wrapper start">
          <h2 className="hb-footer text-right">
            <div>
              <span>회사 설립</span>
            </div>
          </h2>
        </div>
        <div className="wrapper-text">
          <span>2017년 겨울, 각 분야에서 최고의 실력을 갖춘 사람들이 네트워크 보안 시장을 선도한다는 마음 아래 한자리에 모였습니다.</span>
        </div>
      </div>
    </section>
  )
}

const ShowAll = ({click}) => {
  return (
    <section className="history-box">
      <div className="container">
        <div className="wrapper-text">
          <span className="btn" onClick={click}>
            <span>더 많은 내용 보기</span>
          </span>
        </div>
      </div>
    </section>
  )
}

const MovieHelper = ({v}) => {
  function click(e) {
    ReactDOM.render(
      React.createElement( Empty ),
      document.getElementById('Movie')
    );
  }
  return (
    <section className="history-full-movie" onClick={click}>
      <div className="container">
        <button className="btn">&times;</button>
        <div className="video-container">
          <iframe title="history-full-movie" width="853" height="480" src={`https://www.youtube.com/embed/${v}?autoplay=1`} frameBorder="0" allowFullScreen={true} autoPlay={true}></iframe>
        </div>
      </div>
    </section>
  )
}

const Empty = () => {
  return (<div></div>)
}

const SelectionKstartup = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2018.4</h4>
        <div className="wrapper-text">
          <span>중소벤처기업부에서 주관하는 K-Startup 스마트벤처캠퍼스에서 유망 지식서비스 분야의 청년창업기업으로 선정되었습니다.</span>
        </div>
      </div>
    </section>
  )
}

const Ciociso = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2018.5</h4>
        <div className="wrapper ciso1">
          <h2 className="hb-header">
            <div>CISO Conference</div>
          </h2>
          <h2 className="hb-footer text-right">
            <div>360도 보안 분석이 가능한 Network Blackbox</div>
          </h2>
        </div>
        <div className="wrapper ciso2">
          <h2 className="hb-header">
            <div>CISO Conference</div>
          </h2>
        </div>
        <div className="wrapper-text">
          <span>
            CIO미디어 그룹은 1996년에 설립하여 IT 역량 강화를 위해 지속적으로 컨퍼런스, 세미나를 개최하고 있습니다.<br/>
            쿼드마이너는 CIOCISO 매거진의 초청을 받아 Network Blackbox를 통해 신종 위협을 방어하는 방법을 소개하였습니다.
          </span>
        </div>
      </div>
    </section>
  )
}

const Cpo = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2018.6</h4>
        <div className="wrapper cpo1">
          <h2 className="hb-header">
            <div>Korea CPO Forum</div>
          </h2>
          <h2 className="hb-footer text-right">
            <div>Privacy Round Up</div>
          </h2>
        </div>
        <div className="wrapper cpo2">
          <h2 className="hb-header">
            <div>Korea CPO Forum</div>
          </h2>
          <h2 className="hb-footer text-right">
            <div>Privacy Round Up</div>
          </h2>
        </div>
        <div className="wrapper-text">
          <span>
            한국 CPO 포럼은 한국의 대표적인 개인정보보호 전문 포럼입니다.<br/>
            평창 올림픽 개막식 당일 역대급 사이버 공격을 성공적으로 방어, 대응한 사례를 소개하는 자리에서<br/>
            위협을 효과적으로 대응할 수 있는 차세대 보안 솔루션, Network Blackbox를 소개하였습니다.
          </span>
        </div>
      </div>
    </section>
  )
}

const Sosv = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2018.7</h4>
        <div className="wrapper sosv1">
          <h2 className="hb-header">
            <div>
              SOSV<br/>
              <small>The Accelerator VC</small>
            </div>
          </h2>
          <h2 className="hb-footer text-right">
            <div>CROSS-BORDER LANDING AND ACCELERATION</div>
          </h2>
        </div>
        <div className="wrapper sosv2">
          <h2 className="hb-footer text-right">
            <div>CROSS-BORDER LANDING AND ACCELERATION</div>
          </h2>
        </div>
        <div className="wrapper-text">
          <span>차이나 엑셀러레이터와 MOX, SOSV가 공동으로 마련한 행사에서 빠르게 성장하고 있는 스타트업, 쿼드마이너를 소개하였습니다.</span>
        </div>
      </div>
    </section>
  )
}

const Rise = () => {
  function click(e) {
    if($('body').width() >= 840) {
      e.preventDefault();
      ReactDOM.render(
        React.createElement(MovieHelper, {v:'xwmTLYh5nTo'}),
        document.getElementById('Movie')
      );
    }
  }
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2018.7</h4>
        <div className="wrapper rise1">
          <h2 className="hb-header">
            <div>RISE<br/><small>Conference</small></div>
          </h2>
          <h2 className="hb-footer text-right">
            <div>ALPHA Startup</div>
          </h2>
        </div>
        <div className="wrapper rise2">
          <h2 className="hb-footer text-right">
            <div>ALPHA Startup</div>
          </h2>
        </div>
        <div className="wrapper-text">
          <span>7만 명의 참가객이 오는 아시아에서 가장 큰 기술 컨퍼런스, RISE 2018에서 Network Blackbox를 시연하였습니다.</span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=1G50pp-9ADU" className="btn" target="_blank" onClick={click}>
            <FontAwesomeIcon icon={faPlayCircle} /> &nbsp;
            <span>동영상 보기</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const ReshapeTokyo = () => {
  function click(e) {
    if($('body').width() >= 840) {
      e.preventDefault();
      ReactDOM.render(
        React.createElement(MovieHelper, {v:'kq_h-tRPuWs'}),
        document.getElementById('Movie')
      );
    }
  }
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2018.9</h4>
        <div className="wrapper rtokyo1">
          <h2 className="hb-header">
            <div>ReShape Tokyo</div>
          </h2>
          <h2 className="hb-footer text-right">
            <div>Cross-Border Startups</div>
          </h2>
        </div>
        <div className="wrapper rtokyo2">
          <h2 className="hb-footer text-right">
            <div>Demo Day</div>
          </h2>
        </div>
        <div className="wrapper-text">
          <span>
            Network Blackbox의 일본 시장 진출을 위해 01Booster와 함께 사업제휴 미팅을 갖고 데모데이를 진행하였습니다.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=kq_h-tRPuWs" className="btn" target="_blank" onClick={click}>
            <FontAwesomeIcon icon={faPlayCircle} /> &nbsp;
            <span>동영상 보기</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const Dibs = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2018.11</h4>
        <div className="wrapper dibs1">
          <h2 className="hb-header">
            <div>
              Data Innovation Business Scale up<br/>
            </div>
          </h2>
        </div>
        <div className="wrapper dibs2">
        </div>
        <div className="wrapper-text">
          <span>서울디지털재단이 주최하고 있는 빅데이터 분야 창업 기업에 선정되어 IR을 피칭하였습니다.</span>
        </div>
      </div>
    </section>
  )
}

const GsCertificate = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2019.1</h4>
        <div className="wrapper-text">
          <span>사이버 보안 위협에 효과적으로 대응할 수 있는 Network&nbsp;Blackbox가<br/>한국정보통신기술협회(TTA)로부터 국가 소프트웨어 품질 인증인 GS인증 1등급을 획득하였습니다.</span>
        </div>
      </div>
    </section>
  )
}

const PartnerDay = () => {
  function click(e) {
    if($('body').width() >= 840) {
      e.preventDefault();
      ReactDOM.render(
        React.createElement(MovieHelper, {v:'xwmTLYh5nTo'}),
        document.getElementById('Movie')
      );
    }
  }
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2019.1</h4>
        <div className="wrapper partner1">
          <h2 className="hb-header">
            <div>Network Blackbox Partner Day</div>
          </h2>
        </div>
        <div className="wrapper partner2" />
        <div className="wrapper-text">
          <span>
            쿼드마이너는 리노스와 함께 Network Blackbox Partner Day를 성황리에 개최하였습니다.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=xwmTLYh5nTo" className="btn" target="_blank" onClick={click}>
            <FontAwesomeIcon icon={faPlayCircle} /> &nbsp;
            <span>동영상 보기</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const BattleDemo = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2019.2</h4>
        <div className="wrapper battle1">
          <h2 className="hb-header">
            <div>Demo-Day Battle Demo 300</div>
          </h2>
        </div>
        <div className="wrapper battle2" />
        <div className="wrapper-text">
          <span>
            쿼드마이너는 우수 청년창업기업으로 선정되어 스마트벤쳐캠퍼스가 주관하는 데모데이에서 피칭을 하였습니다.
          </span>
        </div>
      </div>
    </section>
  )
}

const ReshapeSingapore = () => {
  function click(e) {
    if($('body').width() >= 840) {
      e.preventDefault();
      ReactDOM.render(
        React.createElement(MovieHelper, {v:'cKHnM9DoJ7A'}),
        document.getElementById('Movie')
      );
    }
  }
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2019.4</h4>
        <div className="wrapper rsingapore1">
          <h2 className="hb-header">
            <div>ReShape Singapore</div>
          </h2>
        </div>
        <div className="wrapper rsingapore2">
          <h2 className="hb-footer text-right">
            <div>Demo Day</div>
          </h2>
        </div>
        <div className="wrapper-text">
          <span>
            싱가포르 VC인 TNB 벤처스에서 주관하는 행사에 검증된 사이버 보안 스타트업으로 참가하여 뜨거운 관심을 받았습니다.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=cKHnM9DoJ7A" className="btn" target="_blank" onClick={click}>
            <FontAwesomeIcon icon={faPlayCircle} /> &nbsp;
            <span>동영상 보기</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const PrivacyGlobalEdge2019 = () => {
  function click(e) {
    if($('body').width() >= 840) {
      e.preventDefault();
      ReactDOM.render(
        React.createElement(MovieHelper, {v:'BZnTKlw7Yd8'}),
        document.getElementById('Movie')
      );
    }
  }
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2019.5</h4>
        <div className="wrapper pge20191">
          <h2 className="hb-header">
            <div>Privacy Global Edge 2019</div>
          </h2>
        </div>
        <div className="wrapper pge20192">
          <h2 className="hb-footer text-right">
            <div>Asia Privacy Bridge Forum</div>
          </h2>
        </div>
        <div className="wrapper-text">
          <span>
            Privacy Global Edge는 개인정보 유출 사고를 막고, 데이터 보호와 활용을 모색하는 글로벌 프라이버시 전문가 축제입니다.<br/>
            쿼드마이너는 PGE 행사를 후원하고, 사이버 위협과 데이터 유출을 방지할 수 있는 Network Blackbox를 시연하였습니다.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=BZnTKlw7Yd8" className="btn" target="_blank" onClick={click}>
            <FontAwesomeIcon icon={faPlayCircle} /> &nbsp;
            <span>동영상 보기</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const G2b = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2019.5</h4>
        <div className="wrapper-text">
          <span>
            풀패킷 기반의 차세대 네트워크 포렌식 솔루션 Network Blackbox가 나라장터 3자단가계약을 통해 조달 등록되었습니다.
          </span>
        </div>
      </div>
    </section>
  )
}

const Top10CyberSecurity = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2019.6</h4>
        <div className="wrapper has-image">
          <img src="/images/history/top10.png" alt="APAC CIO Outlook Top 10 Cyber Security Solution Providers - 2019" />
        </div>
        <div className="wrapper-text">
          <span>
            APAC CIO Outlook에서 2019년 Cyber Security Solution Providers 탑 10에 쿼드마이너가 선정되었습니다.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://cyber-security.apacciooutlook.com/vendor/quad-miners-the-rising-rookie-in-network-security-cid-4326-mid-262.html" className="btn" target="_blank" >
            <FontAwesomeIcon icon={faNewspaper} /> &nbsp;
            <span>기사 보기</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const SkinfosecDistributor = () => {
  function click(e) {
    if($('body').width() >= 840) {
      e.preventDefault();
      ReactDOM.render(
        React.createElement(MovieHelper, {v:'IImCqJQ58kI'}),
        document.getElementById('Movie')
      );
    }
  }
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2019.6</h4>
        <div className="wrapper skinfosec1">
          <h2 className="hb-header">
            <div>Quad Miners x SK infosec 총판계약 체결식</div>
          </h2>
        </div>
        <div className="wrapper skinfosec2">
        </div>
        <div className="wrapper-text">
          <span>
            쿼드마이너는 SK infosec과 Network Blackbox 총판 계약 체결식을 성공적으로 체결하였습니다.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=IImCqJQ58kI" className="btn" target="_blank" onClick={click}>
            <FontAwesomeIcon icon={faPlayCircle} /> &nbsp;
            <span>동영상 보기</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const Nest5th = () => {
  function click(e) {
    if($('body').width() >= 840) {
      e.preventDefault();
      ReactDOM.render(
        React.createElement(MovieHelper, {v:'Zkap_GNTyuo'}),
        document.getElementById('Movie')
      );
    }
  }
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2019.7</h4>
        <div className="wrapper nest5th1">
          <h2 className="hb-header">
            <div>Nest 5th Accelerating Demoday</div>
          </h2>
        </div>
        <div className="wrapper nest5th2">
        </div>
        <div className="wrapper-text">
          <span>
            쿼드마이너는 Start-up NEST와 빅뱅엔젤스, 신용보증기금에서 주최하는 데모데이에 참석하였습니다.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=Zkap_GNTyuo" className="btn" target="_blank" onClick={click}>
            <FontAwesomeIcon icon={faPlayCircle} /> &nbsp;
            <span>동영상 보기</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const ReshapeRoadShow2019 = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2019.9</h4>
        <div className="wrapper reshapeSeoul1">
          <h2 className="hb-header">
            <div>ReShape Roadshow Seoul 2019</div>
          </h2>
        </div>
        <div className="wrapper reshapeSeoul2">
        </div>
        <div className="wrapper-text">
          <span>
            쿼드마이너는 크로스보더 액셀러레이터 빅뱅엔젤스와 AAA가 주관한 행사에 유망 스타트업으로 초대받아 로드쇼를 진행하였습니다.
          </span>
        </div>
      </div>
    </section>
  )
}

const SnaPartnerDay = () => {
  function click(e) {
    if($('body').width() >= 840) {
      e.preventDefault();
      ReactDOM.render(
        React.createElement(MovieHelper, {v:'vH7zyLfBnnw'}),
        document.getElementById('Movie')
      );
    }
  }
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2019.9</h4>
        <div className="wrapper snaPartner1">
          <h2 className="hb-header">
            <div>SNA Partner Day 2019</div>
          </h2>
        </div>
        <div className="wrapper snaPartner2">
        </div>
        <div className="wrapper-text">
          <span>
            쿼드마이너는 SNA와 함께 Partner Day를 성황리에 개최하였습니다.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=vH7zyLfBnnw" className="btn" target="_blank" onClick={click}>
            <FontAwesomeIcon icon={faPlayCircle} /> &nbsp;
            <span>동영상 보기</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const UnipointPartnerDay = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2019.10</h4>
        <div className="wrapper unipointPartner1">
          <h2 className="hb-header">
            <div>Unipoint Partner Day 2019</div>
          </h2>
        </div>
        <div className="wrapper unipointPartner2">
        </div>
        <div className="wrapper-text">
          <span>
            쿼드마이너는 Unipoint와 함께 Partner Day를 성황리에 개최하였습니다.
          </span>
        </div>
      </div>
    </section>
  )
}

const LeenosPartnerDay = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2019.10</h4>
        <div className="wrapper leenosPartner1">
          <h2 className="hb-header">
            <div>Leenos Partner Day</div>
          </h2>
        </div>
        <div className="wrapper leenosPartner2">
        </div>
        <div className="wrapper-text">
          <span>
            쿼드마이너는 리노스와 함께 Partner Day를 성황리에 개최하였습니다.
          </span>
        </div>
      </div>
    </section>
  )
}

const NationalDefense = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2019.10</h4>
        <div className="wrapper nd1">
          <h2 className="hb-header">
            <div>ROK Cyber Command Seminar</div>
          </h2>
        </div>
        <div className="wrapper nd2">
        </div>
        <div className="wrapper-text">
          <span>
            쿼드마이너는 국방부가 주최하는 합동 사이버지휘통신 발전 세미나에 참석하여 네트워크 블랙박스를 시연하였습니다.
          </span>
        </div>
      </div>
    </section>
  )
}

const PublicSolutionMarket = () => {
  function click(e) {
    if($('body').width() >= 840) {
      e.preventDefault();
      ReactDOM.render(
        React.createElement(MovieHelper, {v:'Z9Tap2Y1jpQ'}),
        document.getElementById('Movie')
      );
    }
  }
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2019.11</h4>
        <div className="wrapper psm1">
          <h2 className="hb-header">
            <div>Public Solution Market 2019</div>
          </h2>
        </div>
        <div className="wrapper psm2">
        </div>
        <div className="wrapper-text">
          <span>
            쿼드마이너는 리노스와 함께 한국IT서비스산업협회가 주관하는 공공솔루션마켓에 참가하였습니다.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=Z9Tap2Y1jpQ" className="btn" target="_blank" onClick={click}>
            <FontAwesomeIcon icon={faPlayCircle} /> &nbsp;
            <span>동영상 보기</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const ItExpoBusan2019 = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2019.11</h4>
        <div className="wrapper itexpo1">
          <h2 className="hb-header">
            <div>IT EXPO BUSAN 2019</div>
          </h2>
        </div>
        <div className="wrapper itexpo2">
        </div>
        <div className="wrapper-text">
          <span>
            쿼드마이너는 동남권 최대의 IT 전시박람회인, IT EXPO BUSAN 2019에 참가하였습니다.
          </span>
        </div>
      </div>
    </section>
  )
}

const MoveSunghong = () => {
  function click(e) {
    if($('body').width() >= 840) {
      e.preventDefault();
      ReactDOM.render(
        React.createElement(MovieHelper, {v:'_csSwExOu8g'}),
        document.getElementById('Movie')
      );
    }
  }
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2020.2</h4>
        <div className="wrapper move1">
          <h2 className="hb-header">
            <div>Company HQ Relocation</div>
          </h2>
        </div>
        <div className="wrapper move2">
        </div>
        <div className="wrapper-text">
          <span>
            쿼드마이너는 더 큰 발전과 성장을 위하여 서울 역삼동 성홍타워로 회사를 이전하였습니다.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=_csSwExOu8g" className="btn" target="_blank" onClick={click}>
            <FontAwesomeIcon icon={faPlayCircle} /> &nbsp;
            <span>동영상 보기</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const TopTechCompany2020 = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2020.6</h4>
        <div className="wrapper has-image">
          <img src="/images/history/top_tech_2020.jpg" alt="APAC CIO Outlook Top 10 Cyber Security Solution Providers - 2019" />
        </div>
        <div className="wrapper-text">
          <span>
            APAC BUSINESS Headlines에서 쿼드마이너가 2020년 한국 최고의 테크 기업으로 선정되었습니다.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://apacbusinessheadlines.com/Toptech-web-version/Quad-Miners/" className="btn" target="_blank" >
            <FontAwesomeIcon icon={faNewspaper} /> &nbsp;
            <span>기사 보기</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const Sv2020 = () => {
  function click(e) {
    if($('body').width() >= 840) {
      e.preventDefault();
      ReactDOM.render(
        React.createElement(MovieHelper, {v:'6GOaEw84C_s'}),
        document.getElementById('Movie')
      );
    }
  }
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2020.7</h4>
        <div className="wrapper sv20201">
          <h2 className="hb-header">
            <div>19th Next Generation Security Vision 2020</div>
          </h2>
        </div>
        <div className="wrapper sv20202">
        </div>
        <div className="wrapper-text">
          <span>
            쿼드마이너는 차세대 보안 제품을 소개하는 컨퍼런스에서 진화하는 사이버 공격에 종합적으로 대응할 수 있는 Network Blackbox를 소개하였습니다.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=6GOaEw84C_s" className="btn" target="_blank" onClick={click}>
            <FontAwesomeIcon icon={faPlayCircle} /> &nbsp;
            <span>동영상 보기</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const Gartner = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2020.9</h4>
        <div className="wrapper has-image" style={{
          maxWidth: '180px'
        }}>
          <img src="/images/partners/gartner.png" alt="" />
        </div>
        <div className="wrapper-text">
          <span>
            쿼드마이너는 Gartner 리포트에서 Network Detection and Response의 대표적인 벤더사로 소개되었습니다.
          </span>
          <br/>
          <small>
            Gartner, Emerging Technologies: Adoption Growth Insights for Network Detection and Response.
          </small>
        </div>
      </div>
    </section>
  )
}

const NusEnterprise = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2020.10</h4>
        <div className="wrapper has-image" style={{
          maxWidth: '400px'
        }}>
          <img src="/images/history/nus_enterprise_market_immersion_programme_2020.jpg" alt="NUS Enterprise Market Immersion Programme 2020" />
        </div>
        <div className="wrapper-text">
          <span>
            쿼드마이너는 276 지원 업체 중에 6개만 선정하는 2020 NUS Enterprise Market Immersion 프로그램에서 우수한 성적으로 한국 스타트업 진출자로 선정되었습니다.
          </span>
          <br/>
          <small>
            NUS Enterprise is proud to partner with the Korea Institute of Startup and Entrepreneurship Development (KISED) to host this 8-week accelerator programme which will catalyze opportunities for these start-ups to commercialize solutions with local corporate partners and industry experts.
          </small>
        </div>
      </div>
    </section>
  )
}

const FirstKistiCouncil = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2020.11</h4>
        <div className="wrapper kisti1">
          <h2 className="hb-header">
            <div>2020 The 1st KISTI Council</div>
          </h2>
        </div>
        <div className="wrapper kisti2" />
        <div className="wrapper-text">
          <span>
            쿼드마이너는 2020 제1차 과학기술정보보호협의회에 참여하여, Network Blackbox를 소개하였습니다.
          </span>
        </div>
      </div>
    </section>
  )
}

const Company1000 = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2021.5</h4>
        <div className="wrapper has-image" style={{
          maxWidth: '380px'
        }}>
          <img src="/images/history/logo_ict_fsc.png" alt="" />
        </div>
        <div className="wrapper-text">
          <h4 style={{margin: 0}}>
            쿼드마이너, 금융위원회에서 주관하는 "혁신기업 국가대표 1000"에 선정
          </h4>
          <div>
            금융위원회와 협업한 9개의 부처 중 과기정통부에서 기업의 혁신성, 기술력, 성장성 등을 평가 받아 미래의 혁신을 선도할 혁신 기업으로 최종 선정되었습니다.
          </div>
        </div>
      </div>
    </section>
  )
}

const Top10CloudSolution = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2021.6</h4>
        <div className="wrapper has-image">
          <img src="/images/history/top10_cloud.png" alt="Top 10 Cloud Solution Providers - 2021 APAC CIO Outlook" />
        </div>
        <div className="wrapper-text">
          <span>
            쿼드마이너가 APAC CIO Outlook에서 2021년 Cloud Solution Providers Top 10 Company로 선정되었습니다.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://cloud.apacciooutlook.com/vendor/quad-miners-delivering-a-comprehensive-cloud-security-platform-cid-5866-mid-434.html" className="btn" target="_blank" >
            <FontAwesomeIcon icon={faNewspaper} /> &nbsp;
            <span>기사 보기</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const Dupex2021 = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2021.10</h4>
        <div className="wrapper dupex1">
          <h2 className="hb-header">
            <div>DUPEX 2021</div>
          </h2>
        </div>
        <div className="wrapper dupex2" />
        <div className="wrapper-text">
          <span>
            쿼드마이너는 아시아 최대 국방전시회인 DUPEX 2021에 참가하였습니다. 고도화되고 있는 사이버 전장에서 전통적인 탐지, 분석 기법과 한정된 데이터 수집 체계에 의지하고 있는 현보안 체계의 한계와 리스크를 설명하여, 주요 국가 시설을 보호하고 지능화된 사이버 위협에 대응하기 위한 능동적 사이버 방호 체계 구축을 제언하였습니다.
          </span>
        </div>
      </div>
    </section>
  )
}

const Kodit2021 = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2021.10</h4>
        <div className="wrapper kodit1">
          <h2 className="hb-header">
            <div>
              퍼스트펭귄형 창업기업 선정
            </div>
          </h2>
        </div>
        <div className="wrapper-text">
          쿼드마이너는 신용보증기금으로부터 국가 경제 핵심성장 동력 산업을 이끌 선도 기업으로 평가 받았습니다.
        </div>
      </div>
    </section>
  )
}

const IglooMou = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2022.3</h4>
        <div className="wrapper igloo_mou">
          <h2 className="hb-header">
            <div>
              쿼드마이너·이글루코퍼레이션, 차세대 보안관제 기술 개발 사업 협약 체결
            </div>
          </h2>
        </div>
        <div className="wrapper-text">
          쿼드마이너는 글로벌 정보보안기업 이글루코퍼레이션과 차세대 보안관제 기술 개발을 위한 업무 협약을 체결하였습니다.
        </div>
      </div>
    </section>
  )
}

const Gartner3 = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2022.4</h4>
        <div className="wrapper has-image" style={{
          maxWidth: '180px'
        }}>
          <img src="/images/partners/gartner.png" alt="" />
        </div>
        <div className="wrapper-text">
          <span>
            쿼드마이너는 3년 연속 Gartner 리포트에서 Network Detection and Response의 대표적인 벤더사로 소개되었습니다.
          </span>
        </div>
      </div>
    </section>
  )
}

const SeriesA = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2022.6</h4>
        <div className="wrapper series_a">
          <h2 className="hb-header"> </h2>
        </div>
        <div className="wrapper-text">
          <span>
            쿼드마이너는 글로벌 시장 확장을 위해 82억원을 확보, 시리즈A 투자유치를 완료하였습니다.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=173371" className="btn" target="_blank" >
            <FontAwesomeIcon icon={faNewspaper} /> &nbsp;
            <span>기사 보기</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const Gptw = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2022.8</h4>
        <div className="wrapper has-image">
          <img src="/images/history/gptw.jpg" alt="" />
        </div>
        <div className="wrapper-text">
          <span>
            쿼드마이너는 가족친화기업에 이어서, 일하기 좋은 기업 인증을 받았습니다.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.greatplacetowork.co.kr/kr/certification/certified-companies/quadminers" className="btn" target="_blank" >
            <span>자세히 보기</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const Won35uskorea = () => {
  return(
    <section className="history-box">
      <div className="container">
        <h4 className="date">2022.8</h4>
        <div className="wrapper ukc20221">
          <h2 className="hb-header">
            <div>
              UKC 2022 Washington D.C.
            </div>
          </h2>
        </div>
        <div className="wrapper ukc20222">
        </div>
        <div className="wrapper-text">
          쿼드마이너는 워싱턴 D.C.에서 개최한 US-Korea Conference의 스타트업 피칭 부문에서 1등을 수상하였습니다.
        </div>
        <a rel="noreferrer noopener" href="https://ukc.ksea.org/ukc2022/" className="btn" target="_blank" >
          <span>자세히 보기</span>
        </a>
      </div>
    </section>
  )
}

const Forbes100 = () => {
  return(
    <section className="history-box">
      <div className="container">
        <h4 className="date">2022.8</h4>
        <div className="wrapper has-image">
          <img src="/images/history/forbes_100_asia.png" alt="" />
        </div>
        <div className="wrapper-text">
          <span>
            Forbes에서 쿼드마이너를 2022년 아시아 100대 기업으로 선정하였습니다.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.forbes.com/sites/forbesasiateam/2022/08/29/forbes-asia-100-to-watch-2022/?sh=737c5311df33" className="btn" target="_blank" >
            <span>자세히 보기</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const BabyUnicorn = () => {
  return(
    <section className="history-box">
      <div className="container">
        <h4 className="date">2022.10</h4>
        <div className="wrapper has-image">
          <img src="/images/history/unicorn.jpeg" alt="" />
        </div>
        <div className="wrapper-text">
          <span>
            아기유니콘200 육성사업 지원 대상은 연구개발 완료를 앞둔 경쟁력 있는 제품이나 서비스를 보유해 향후 폭발적 성장이 기대되는 기업입니다. 쿼드마이너는 아기유니콘200에서 41개 기업 중 하나로 최종 선정되었습니다.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.korea.kr/news/pressReleaseView.do?newsId=156533711" className="btn" target="_blank" >
            <span>자세히 보기</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const ICTExpoInJapan = () => {
  return(
    <section className="history-box">
      <div className="container">
        <h4 className="date">2022.12</h4>
        <div className="wrapper ictexpojapan1">
          <h2 className="hb-header">
            <div>
              2022 ICT Expo in Japan
            </div>
          </h2>
        </div>
        <div className="wrapper ictexpojapan2" />
        <div className="wrapper-text">
          <span>
            쿼드마이너는 ICT 엑스포 in 저팬에서 일본 사테라이토오피스, 라이오니스와 2억엔 규모의 공급계약을 체결하였습니다.
          </span>
          <br/>
        </div>
      </div>
    </section>
  )
}

const Gptw2023 = () => {
  function click(e) {
    if($('body').width() >= 840) {
      e.preventDefault();
      ReactDOM.render(
        React.createElement(MovieHelper, {v:'ZLJxhez2Oxg'}),
        document.getElementById('Movie')
      );
    }
  }
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2023.02</h4>
        <div className="wrapper has-image">
          <img src="/images/history/2023_best_workplaces_korea.jpg" alt="" />
        </div>
        <div className="wrapper-text">
          <span>쿼드마이너는 좋은 일터를 만들기 위해 많은 노력을 기울이고 있습니다. 대한민국 GPTW 혁신리더상, 제21회 대한민국 일하기 좋은 기업 13위, 대한민국 여성 워킹맘이 일하기 좋은 기업 등을 수상하였습니다.</span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=1G50pp-9ADU" className="btn" target="_blank" onClick={click}>
            <FontAwesomeIcon icon={faPlayCircle} /> &nbsp;
            <span>동영상 보기</span>
          </a>
        </div>
      </div>
    </section>
  )
};

const Piolink = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2023.03</h4>
        <div className="wrapper piolink1">
          <h2 className="hb-header">
            <div>
              파이오링크 총판계약 체결식
            </div>
          </h2>
        </div>
        <div className="wrapper piolink2" />
        <div className="wrapper-text">
          <span>
            쿼드마이너는 네트워크 및 보안서비스 전문기업 파이오링크와 NDR 솔루션 판매 및 기술지원, 서비스를 제공하는 총판 계약을 체결하였습니다.
          </span>
          <br/>
        </div>
      </div>
    </section>
  )
}

const Gartner4 = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2023.4</h4>
        <div className="wrapper has-image" style={{
          maxWidth: '180px'
        }}>
          <img src="/images/partners/gartner.png" alt="" />
        </div>
        <div className="wrapper-text">
          <span>
            쿼드마이너는 가트너의 "신흥기술: 네트워크 탐지 및 대응을 위한 채택 성장 통찰력" 보고서에 NDR 대표 벤더사로 4년 연속 등재되었습니다.
          </span>
        </div>
      </div>
    </section>
  )
}

const PiolinkZeroTrust = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2023.08</h4>
        <div className="wrapper piolink_mou1">
          <h2 className="hb-header">
            <div>
              파이오링크 네트워크 제로트러스트 보안 업무 협약
            </div>
          </h2>
        </div>
        <div className="wrapper piolink_mou2" />
        <div className="wrapper-text">
          <span>
            쿼드마이너와 파이오링크는 양사가 보유하고 있는 네트워크 보안 기술과 빅데이터 분석 기술 협력을 통해 기업 내부망에서의 제로트러스트 보안 구현을 위한 기술을 공동 개발하기로 협약하였습니다.
          </span>
          <br/>
        </div>
      </div>
    </section>
  )
}

const SeriesB = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2023.10</h4>
        <div className="wrapper series_b">
          <h2 className="hb-header"> </h2>
        </div>
        <div className="wrapper-text">
          <span>
            쿼드마이너는 시리즈B 150억원 투자유치를 완료하였습니다.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.datanet.co.kr/news/articleView.html?idxno=188241" className="btn" target="_blank" >
            <FontAwesomeIcon icon={faNewspaper} /> &nbsp;
            <span>기사 보기</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const InnovationAward = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2023.12</h4>
        <div className="wrapper innovation_award">
          <h2 className="hb-header"> </h2>
        </div>
        <div className="wrapper-text">
          <span>
            쿼드마이너는 과학기술정보통신부가 주최한 대한민국 정보보호제품 혁신대상에서 장관상을 수상하였습니다.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.etnews.com/20231206000143" className="btn" target="_blank" >
            <FontAwesomeIcon icon={faNewspaper} /> &nbsp;
            <span>기사 보기</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const InsungDigital = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2024.2</h4>
        <div className="wrapper insung_digital">
          <h2 className="hb-header"> </h2>
        </div>
        <div className="wrapper-text">
          <span>
            쿼드마이너는 기술 유통 전문 기업인 인성디지탈과 차세대 네트워크 보안 솔루션 총판 계약을 체결하였습니다.
          </span>
        </div>
      </div>
    </section>
  )
}

const Gptw2024 = () => {
  function click(e) {
    if($('body').width() >= 840) {
      e.preventDefault();
      ReactDOM.render(
        React.createElement(MovieHelper, {v:'iYMfO3f_9pU'}),
        document.getElementById('Movie')
      );
    }
  }
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2024.02</h4>
        <div className="wrapper gptw2024_1">
          <h2 className="hb-header">
            <div>
              GPTW 2024 일하기 좋은 기업 2년 연속 선정
            </div>
          </h2>
        </div>
        <div className="wrapper gptw2024_2" />
        <div className="wrapper-text">
          <span>
            쿼드마이너는 2024년에 대한민국에서 일하기 좋은 100대 기업으로 GPTW에 의해 선정되었습니다. 또한, 글로벌 ESG 인권경영 인증을 획득하였습니다. 그리고 공동대표인 박범중과 홍재완은 대한민국에서 가장 존경받는 CEO로 선정되었습니다.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=iYMfO3f_9pU" className="btn" target="_blank" onClick={click}>
            <FontAwesomeIcon icon={faPlayCircle} /> &nbsp;
            <span>동영상 보기</span>
          </a>
        </div>
      </div>
    </section>
  )
};

const KGlobal2023 = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2024.4</h4>
        <div className="wrapper has-image" style={{
          maxWidth: '190px'
        }}>
          <img src="/images/history/logo_ict.png" alt="" />
        </div>
        <div className="wrapper-text">
          <span>
            과학기술정보통신부에서 진행한 K-글로벌 프로젝트의 해외진출 분야에서 쿼드마이너가 대표 성과 사례 기업으로 선정되었습니다.
          </span>
        </div>
      </div>
    </section>
  )
}

const InnovationIcon = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2024.05</h4>
        <div className="wrapper innovationicon">
          <h2 className="hb-header">
            <div>
              제 11기 혁신아이콘 협약식
            </div>
          </h2>
        </div>
        <div className="wrapper-text">
          <span>
            쿼드마이너는 신용보증기금으로부터 신기술 또는 혁신적인 비즈니스 모델을 가진 혁신 스타트업으로 선정되었습니다.
          </span>
        </div>
      </div>
    </section>
  )
}

const JinnHyunAISperaMou = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2024.06</h4>
        <div className="wrapper jinaimou">
          <h2 className="hb-header">
            <div>
              진앤현시큐리티, 쿼드마이너, 에이아이스페라 상호업무협약 체결
            </div>
          </h2>
        </div>
        <div className="wrapper-text">
          <span>
            사이버보안 3사는 상호 강점을 결합하여 사이버보안 시장에서 긴밀한 협력과 교류를 바탕으로 고도화된 기술과 강화된 서비스를 제공할 것을 협약하였습니다.
          </span>
        </div>
      </div>
    </section>
  )
}


const DefenseSecurityConference19 = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2024.07</h4>
        <div className="wrapper defensesecurityconference19_1">
          <h2 className="hb-header">
            <div>
              제19회 국방보안 컨퍼런스
            </div>
          </h2>
        </div>
        <div className="wrapper defensesecurityconference19_2" />
        <div className="wrapper-text">
          <span>
            쿼드마이너는 국방부가 주최하고 국군방첩사령부가 주관하는 국방보안컨퍼런스에 참가하여 K-방산, K-보안 분야에 대응할 수 있는 기술력을 선보였습니다.
          </span>
        </div>
      </div>
    </section>
  )
}

const WenyoutechMou = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2024.07</h4>
        <div className="wrapper wenyoutech" />
        <div className="wrapper-text">
          <span>
            쿼드마이너는 위엔유텍과 함께 SIEM 사업을 공동발굴하고 상호 보유한 기술교환을 통해 사업을 확장해나가기 위해 상호업무협약을 체결하였습니다.
          </span>
        </div>
      </div>
    </section>
  )
}

const CyberDSA2024 = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2024.08</h4>
        <div className="wrapper cyberdsa2024_1">
          <h2 className="hb-header">
            <div>
              Cyber DSA 2024
            </div>
          </h2>
        </div>
        <div className="wrapper cyberdsa2024_2" />
        <div className="wrapper-text">
          <span>
            쿼드마이너는 쿠알라룸푸르에서 열린 CyberDSA에 참가하여 사이버 보안 및 방위 분야의 전문가들과 교류하였습니다.
          </span>
        </div>
      </div>
    </section>
  )
}

export default History;