import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import ScrollToTop from "common/ScrollToTop";
import Home from "ko/Home";
import NetworkBlackbox from "ko/NetworkBlackbox";
// import FpNids from 'ko/FpNids';
import InsiderThreat from "ko/InsiderThreat";
import CloudBlackbox from "ko/CloudBlackbox";
import QuadX from "ko/QuadX";
import Partners from "ko/Partners";
import CaseStudy from "ko/CaseStudy";
import ContactUs from "ko/ContactUs";
import About from "ko/About";
import Career from "ko/Career";
import History from "ko/History";
import Press from "ko/Press";
import HelpDesk from "ko/HelpDesk";

const BodyKo = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        {["/", "index.html"].map((d) => (
          <Route
            path={d}
            key={d}
            element={<Navigate to={`/ko/index.html`} />}
          />
        ))}

        <Route path="/ko/index.html" element={<Home />} />
        <Route path="/ko/network_blackbox.html" element={<NetworkBlackbox />} />
        {/* <Route path="/ko/fp_nids.html" element={<FpNids />} /> */}
        <Route path="/ko/insider_threat.html" element={<InsiderThreat />} />
        <Route path="/ko/cloud_blackbox.html" element={<CloudBlackbox />} />
        <Route path="/ko/quadx.html" element={<QuadX />} />

        <Route path="/ko/partners.html" element={<Partners />} />
        <Route path="/ko/case_studies.html" element={<CaseStudy />} />
        <Route path="/ko/contact_us.html" element={<ContactUs />} />

        <Route path="/ko/about.html" element={<About />} />
        <Route path="/ko/career.html" element={<Career />} />
        <Route path="/ko/history.html" element={<History />} />
        <Route path="/ko/press.html" element={<Press />} />

        <Route path="/ko/help_desk.html" element={<HelpDesk />} />
      </Routes>
    </Router>
  );
};

export default BodyKo;
