import Wrapper from 'common/Wrapper';
import 'styles/History.scss';
import { useState } from 'react';
import $ from "jquery";
import React from 'react';
import * as ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/pro-regular-svg-icons';
import { faNewspaper } from '@fortawesome/pro-duotone-svg-icons';

const History = () => {
  const [show, setShow] = useState(false);
  function click() {
    setShow(true);
  }

  return (
    <Wrapper active="History" lang="en">
      <Intro />
      <div>
        <CyberDSA2024 />
        <WenyoutechMou />
        <DefenseSecurityConference19 />
        <JinnHyunAISperaMou />
        <KGlobal2023 />
        <InnovationIcon />
        <Gptw2024 />
        <InsungDigital />
        <InnovationAward />
        <SeriesB />
        {!show && <ShowAll click={click} />}
      </div>

      {show && <div>
        <PiolinkZeroTrust />
        <Gartner4 />
        <Piolink />
        <Gptw2023 />
        <ICTExpoInJapan />
        <BabyUnicorn />
        <Forbes100 />
        <Won35uskorea />
        <Gptw />
        <SeriesA />
        <Gartner3 />
        <IglooMou />
        <Kodit2021 />
        <Dupex2021 />
        <Top10CloudSolution />
        <Company1000 />
        <FirstKistiCouncil />
        <NusEnterprise />
        <Gartner />
        <Sv2020 />
        <TopTechCompany2020 />
        <MoveSunghong />
        <ItExpoBusan2019 />
        <PublicSolutionMarket />
        <NationalDefense />
        <LeenosPartnerDay />
        <UnipointPartnerDay />
        <SnaPartnerDay />
        <ReshapeRoadShow2019 />
        <Nest5th />
        <SkinfosecDistributor />
        <Top10CyberSecurity />
        <G2b />
        <PrivacyGlobalEdge2019 />
        <ReshapeSingapore />
        <BattleDemo />
        <PartnerDay />
        <GsCertificate />
        <Dibs />
        <ReshapeTokyo />
        <Rise />
        <Sosv />
        <Cpo />
        <Ciociso />
        <SelectionKstartup />
        <Start />
      </div>}

      <div id="Movie"></div>
    </Wrapper>
  )
}

const Intro = () => {
  return (
    <section className="history-intro">
      <div className="container">
        <h2>Connecting People and Technology to exploit into the future</h2>
      </div>
    </section>
  )
}

const Start = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2017.11</h4>
        <div className="wrapper start">
          <h2 className="hb-footer text-right">
            <div>
              <span>Established in 2017</span>
            </div>
          </h2>
        </div>
        <div className="wrapper-text">
          <span>In the winter of 2017, experts with the best skills in each field came together to lead the network security market.</span>
        </div>
      </div>
    </section>
  )
}

const ShowAll = ({click}) => {
  return (
    <section className="history-box">
      <div className="container">
        <div className="wrapper-text">
          <span className="btn" onClick={click}>
            <span>See more</span>
          </span>
        </div>
      </div>
    </section>
  )
}

const MovieHelper = ({v}) => {
  function click(e) {
    ReactDOM.render(
      React.createElement( Empty ),
      document.getElementById('Movie')
    );
  }
  return (
    <section className="history-full-movie" onClick={click}>
      <div className="container">
        <button className="btn">&times;</button>
        <div className="video-container">
          <iframe title="history-full-movie" width="853" height="480" src={`https://www.youtube.com/embed/${v}?autoplay=1`} frameBorder="0" allowFullScreen={true} autoPlay={true}></iframe>
        </div>
      </div>
    </section>
  )
}

const Empty = () => {
  return (<div></div>)
}

const SelectionKstartup = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2018.4</h4>
        <div className="wrapper-text">
          <span>It was selected as Businesses founded by a young entrepreneur in the promising Knowledge Service field from K-Startup Smart Venture Campus,
hosted by Small and Medium Business Administration.</span>
        </div>
      </div>
    </section>
  )
}

const Ciociso = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2018.5</h4>
        <div className="wrapper ciso1">
          <h2 className="hb-header">
            <div>CISO Conference</div>
          </h2>
          <h2 className="hb-footer text-right">
            <div>Network Blackbox <br/> analyze security in 360 degree</div>
          </h2>
        </div>
        <div className="wrapper ciso2">
          <h2 className="hb-header">
            <div>CISO Conference</div>
          </h2>
        </div>
        <div className="wrapper-text">
          <span>
          The CIO Media Group was established in 1996.<br/>
          From that day to present, CIO media has annually held conferences and seminars to educate and follow IT Security Trends.<br/>
          Quad Miners was invited by CIOCISO magazine to present our own Security Solution "Network Blackbox"<br/>
          to show the world how to defend against new and unknown threats.
          </span>
        </div>
      </div>
    </section>
  )
}

const Cpo = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2018.6</h4>
        <div className="wrapper cpo1">
          <h2 className="hb-header">
            <div>Korea CPO Forum</div>
          </h2>
          <h2 className="hb-footer text-right">
            <div>Privacy Round Up</div>
          </h2>
        </div>
        <div className="wrapper cpo2">
          <h2 className="hb-header">
            <div>Korea CPO Forum</div>
          </h2>
          <h2 className="hb-footer text-right">
            <div>Privacy Round Up</div>
          </h2>
        </div>
        <div className="wrapper-text">
          <span>
            Korea CPO Forum is Korea's leading privacy protection forum.<br/>
            Before the opening ceremony of the PyeongChang Olympic Games,<br/>
            Quad Miners were given the opportunity to introduced Network Blackbox,<br/>
            a next-generation security solution that can effectively respond to threats<br/>
            while introducing successful countermeasures against cyber attacks.
          </span>
        </div>
      </div>
    </section>
  )
}

const Sosv = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2018.7</h4>
        <div className="wrapper sosv1">
          <h2 className="hb-header">
            <div>
              SOSV<br/>
              <small>The Accelerator VC</small>
            </div>
          </h2>
          <h2 className="hb-footer text-right">
            <div>CROSS-BORDER LANDING AND ACCELERATION</div>
          </h2>
        </div>
        <div className="wrapper sosv2">
          <h2 className="hb-footer text-right">
            <div>CROSS-BORDER LANDING AND ACCELERATION</div>
          </h2>
        </div>
        <div className="wrapper-text">
          <span>Out of hundreds of other Start-ups, Quad Miners was selected to introduce our Network Blackbox as a fast-growing Security Company.<br />
          This event was organized by China Accelerator, MOX and SOSV.</span>
        </div>
      </div>
    </section>
  )
}

const Rise = () => {
  function click(e) {
    if($('body').width() >= 840) {
      e.preventDefault();
      ReactDOM.render(
        React.createElement(MovieHelper, {v:'xwmTLYh5nTo'}),
        document.getElementById('Movie')
      );
    }
  }
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2018.7</h4>
        <div className="wrapper rise1">
          <h2 className="hb-header">
            <div>RISE<br/><small>Conference</small></div>
          </h2>
          <h2 className="hb-footer text-right">
            <div>ALPHA Startup</div>
          </h2>
        </div>
        <div className="wrapper rise2">
          <h2 className="hb-footer text-right">
            <div>ALPHA Startup</div>
          </h2>
        </div>
        <div className="wrapper-text">
          <span>Quad Miners' Network Blackbox was selected to operate a Booth at one of the largest Asia Tech Conference called RISE 2018.<br />
          Conference was packed with over 70K participants and Quad Miners' crew were busy demonstrating<br />
          how Network Blackbox can defend against any threats to your network.</span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=1G50pp-9ADU" className="btn" target="_blank" onClick={click}>
            <FontAwesomeIcon icon={faPlayCircle} /> &nbsp;
            <span>Watch the Clip</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const ReshapeTokyo = () => {
  function click(e) {
    if($('body').width() >= 840) {
      e.preventDefault();
      ReactDOM.render(
        React.createElement(MovieHelper, {v:'kq_h-tRPuWs'}),
        document.getElementById('Movie')
      );
    }
  }
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2018.9</h4>
        <div className="wrapper rtokyo1">
          <h2 className="hb-header">
            <div>ReShape Tokyo</div>
          </h2>
          <h2 className="hb-footer text-right">
            <div>Cross-Border Startups</div>
          </h2>
        </div>
        <div className="wrapper rtokyo2">
          <h2 className="hb-footer text-right">
            <div>Demo Day</div>
          </h2>
        </div>
        <div className="wrapper-text">
          <span>
          Quad Miners participated in a business alliance meeting with 01Booster to advance Network Blackbox into the Japanese market and did demo day.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=kq_h-tRPuWs" className="btn" target="_blank" onClick={click}>
            <FontAwesomeIcon icon={faPlayCircle} /> &nbsp;
            <span>Watch the Clip</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const Dibs = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2018.11</h4>
        <div className="wrapper dibs1">
          <h2 className="hb-header">
            <div>
              Data Innovation Business Scale up<br/>
            </div>
          </h2>
        </div>
        <div className="wrapper dibs2">
        </div>
        <div className="wrapper-text">
          <span>Quad Miners was selected to make a presentation at a Big Data IR (Investor Relation).</span>
        </div>
      </div>
    </section>
  )
}

const GsCertificate = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2019.1</h4>
        <div className="wrapper-text">
          <span>Network Blackbox, a Cyber Threat Security solution has received GS Certified Level 1.</span>
        </div>
      </div>
    </section>
  )
}

const PartnerDay = () => {
  function click(e) {
    if($('body').width() >= 840) {
      e.preventDefault();
      ReactDOM.render(
        React.createElement(MovieHelper, {v:'xwmTLYh5nTo'}),
        document.getElementById('Movie')
      );
    }
  }
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2019.1</h4>
        <div className="wrapper partner1">
          <h2 className="hb-header">
            <div>Network Blackbox Partner Day</div>
          </h2>
        </div>
        <div className="wrapper partner2" />
        <div className="wrapper-text">
          <span>
          Partner Day is an exclusive event for Quad Miners' Network Security Solution Partners,<br /> Marketplace Vendors, Corporate Resellers and Training Partners.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=xwmTLYh5nTo" className="btn" target="_blank" onClick={click}>
            <FontAwesomeIcon icon={faPlayCircle} /> &nbsp;
            <span>Watch the Clip</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const BattleDemo = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2019.2</h4>
        <div className="wrapper battle1">
          <h2 className="hb-header">
            <div>Demo-Day Battle Demo 300</div>
          </h2>
        </div>
        <div className="wrapper battle2" />
        <div className="wrapper-text">
          <span>
          Quad Miners was selected as a distinguished young startup company <br />and pitched a company speech at the Demo Day organized by Smart Venture Campus.
          </span>
        </div>
      </div>
    </section>
  )
}

const ReshapeSingapore = () => {
  function click(e) {
    if($('body').width() >= 840) {
      e.preventDefault();
      ReactDOM.render(
        React.createElement(MovieHelper, {v:'cKHnM9DoJ7A'}),
        document.getElementById('Movie')
      );
    }
  }
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2019.4</h4>
        <div className="wrapper rsingapore1">
          <h2 className="hb-header">
            <div>ReShape Singapore</div>
          </h2>
        </div>
        <div className="wrapper rsingapore2">
          <h2 className="hb-footer text-right">
            <div>Demo Day</div>
          </h2>
        </div>
        <div className="wrapper-text">
          <span>
          Quad Miners grabbed the spotlight as a proven cybersecurity startup at an event organized by the Singapore VC TNB Venture.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=cKHnM9DoJ7A" className="btn" target="_blank" onClick={click}>
            <FontAwesomeIcon icon={faPlayCircle} /> &nbsp;
            <span>Watch the Clip</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const PrivacyGlobalEdge2019 = () => {
  function click(e) {
    if($('body').width() >= 840) {
      e.preventDefault();
      ReactDOM.render(
        React.createElement(MovieHelper, {v:'BZnTKlw7Yd8'}),
        document.getElementById('Movie')
      );
    }
  }
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2019.5</h4>
        <div className="wrapper pge20191">
          <h2 className="hb-header">
            <div>Privacy Global Edge 2019</div>
          </h2>
        </div>
        <div className="wrapper pge20192">
          <h2 className="hb-footer text-right">
            <div>Asia Privacy Bridge Forum</div>
          </h2>
        </div>
        <div className="wrapper-text">
          <span>
          Privacy Global Edge(PGE) is a global privacy conference for the professionals that seeks to prevent personal data breach by protecting and utilizing data. 
          Quad Miners sponsored the Privacy Global Edge event and demonstrated Network Blackbox to help prevent cyber threats and data leakage.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=BZnTKlw7Yd8" className="btn" target="_blank" onClick={click}>
            <FontAwesomeIcon icon={faPlayCircle} /> &nbsp;
            <span>Watch the Clip</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const G2b = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2019.5</h4>
        <div className="wrapper-text">
          <span>
            Network Blackbox officially registered as a Public Procurement Service product.
          </span>
        </div>
      </div>
    </section>
  )
}

const Top10CyberSecurity = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2019.6</h4>
        <div className="wrapper has-image">
          <img src="/images/history/top10.png" alt="APAC CIO Outlook Top 10 Cyber Security Solution Providers - 2019" />
        </div>
        <div className="wrapper-text">
          <span>
            Top 10 Cyber Security Solution Providers - 2019 APAC CIO Outlook
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://cyber-security.apacciooutlook.com/vendor/quad-miners-the-rising-rookie-in-network-security-cid-4326-mid-262.html" className="btn" target="_blank">
            <FontAwesomeIcon icon={faNewspaper} /> &nbsp;
            <span>View Article</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const SkinfosecDistributor = () => {
  function click(e) {
    if($('body').width() >= 840) {
      e.preventDefault();
      ReactDOM.render(
        React.createElement(MovieHelper, {v:'IImCqJQ58kI'}),
        document.getElementById('Movie')
      );
    }
  }
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2019.6</h4>
        <div className="wrapper skinfosec1">
          <h2 className="hb-header">
            <div>Quad Miners x SK Infosec Distributor<br /> Agreement Signing Ceremony</div>
          </h2>
        </div>
        <div className="wrapper skinfosec2">
        </div>
        <div className="wrapper-text">
          <span>
          Quad Miners successfully entered into a contract to be the sole distributor with SK infosec and Network Blackbox.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=IImCqJQ58kI" className="btn" target="_blank" onClick={click}>
            <FontAwesomeIcon icon={faPlayCircle} /> &nbsp;
            <span>Watch the Clip</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const Nest5th = () => {
  function click(e) {
    if($('body').width() >= 840) {
      e.preventDefault();
      ReactDOM.render(
        React.createElement(MovieHelper, {v:'Zkap_GNTyuo'}),
        document.getElementById('Movie')
      );
    }
  }
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2019.7</h4>
        <div className="wrapper nest5th1">
          <h2 className="hb-header">
            <div>Nest 5th Accelerating Demoday</div>
          </h2>
        </div>
        <div className="wrapper nest5th2">
        </div>
        <div className="wrapper-text">
          <span>
            Quad Miners attended DEMO DAY hosted by Start-up NEST, Bigbang Angels, and KODIT (KOREA CREDIT GUARANTEE FUND).
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=Zkap_GNTyuo" className="btn" target="_blank" onClick={click}>
            <FontAwesomeIcon icon={faPlayCircle} /> &nbsp;
            <span>Watch the Clip</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const ReshapeRoadShow2019 = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2019.9</h4>
        <div className="wrapper reshapeSeoul1">
          <h2 className="hb-header">
            <div>ReShape Roadshow Seoul 2019</div>
          </h2>
        </div>
        <div className="wrapper reshapeSeoul2">
        </div>
        <div className="wrapper-text">
          <span>
          Quad Miners was invited as a promising startup to an event hosted by the cross-boarder accelerator Bigbangangels and AAA and had a road show.
          </span>
        </div>
      </div>
    </section>
  )
}

const SnaPartnerDay = () => {
  function click(e) {
    if($('body').width() >= 840) {
      e.preventDefault();
      ReactDOM.render(
        React.createElement(MovieHelper, {v:'vH7zyLfBnnw'}),
        document.getElementById('Movie')
      );
    }
  }
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2019.9</h4>
        <div className="wrapper snaPartner1">
          <h2 className="hb-header">
            <div>SNA Partner Day 2019</div>
          </h2>
        </div>
        <div className="wrapper snaPartner2">
        </div>
        <div className="wrapper-text">
          <span>
            Quad Miners successfully held Partner Day with SNA.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=vH7zyLfBnnw" className="btn" target="_blank" onClick={click}>
            <FontAwesomeIcon icon={faPlayCircle} /> &nbsp;
            <span>Watch the Clip</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const UnipointPartnerDay = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2019.10</h4>
        <div className="wrapper unipointPartner1">
          <h2 className="hb-header">
            <div>Unipoint Partner Day 2019</div>
          </h2>
        </div>
        <div className="wrapper unipointPartner2">
        </div>
        <div className="wrapper-text">
          <span>
            Quad Miners successfully held Partner Day with Unipoint.
          </span>
        </div>
      </div>
    </section>
  )
}

const LeenosPartnerDay = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2019.10</h4>
        <div className="wrapper leenosPartner1">
          <h2 className="hb-header">
            <div>Leenos Partner Day</div>
          </h2>
        </div>
        <div className="wrapper leenosPartner2">
        </div>
        <div className="wrapper-text">
          <span>
            Quad Miners successfully held Partner Day with Leenos.
          </span>
        </div>
      </div>
    </section>
  )
}

const NationalDefense = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2019.10</h4>
        <div className="wrapper nd1">
          <h2 className="hb-header">
            <div>ROK Cyber Command Seminar</div>
          </h2>
        </div>
        <div className="wrapper nd2">
        </div>
        <div className="wrapper-text">
          <span>
          Quad Miners participated in the Korea Cyber Command Seminar, hosted by ROK Dept. of Defense, and showcased a Network Blackbox demo.
          </span>
        </div>
      </div>
    </section>
  )
}

const PublicSolutionMarket = () => {
  function click(e) {
    if($('body').width() >= 840) {
      e.preventDefault();
      ReactDOM.render(
        React.createElement(MovieHelper, {v:'Z9Tap2Y1jpQ'}),
        document.getElementById('Movie')
      );
    }
  }
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2019.11</h4>
        <div className="wrapper psm1">
          <h2 className="hb-header">
            <div>Public Solution Market 2019</div>
          </h2>
        </div>
        <div className="wrapper psm2">
        </div>
        <div className="wrapper-text">
          <span>
            Quad Miners intoduces Network Blackbox in the Public Solution Market segment.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=Z9Tap2Y1jpQ" className="btn" target="_blank" onClick={click}>
            <FontAwesomeIcon icon={faPlayCircle} /> &nbsp;
            <span>Watch the Clip</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const ItExpoBusan2019 = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2019.11</h4>
        <div className="wrapper itexpo1">
          <h2 className="hb-header">
            <div>IT EXPO BUSAN 2019</div>
          </h2>
        </div>
        <div className="wrapper itexpo2">
        </div>
        <div className="wrapper-text">
          <span>
            Quad Miners participated in the IT EXPO BUSAN 2019.
          </span>
        </div>
      </div>
    </section>
  )
}

const MoveSunghong = () => {
  function click(e) {
    if($('body').width() >= 840) {
      e.preventDefault();
      ReactDOM.render(
        React.createElement(MovieHelper, {v:'_csSwExOu8g'}),
        document.getElementById('Movie')
      );
    }
  }
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2020.2</h4>
        <div className="wrapper move1">
          <h2 className="hb-header">
            <div>Company HQ Relocation</div>
          </h2>
        </div>
        <div className="wrapper move2">
        </div>
        <div className="wrapper-text">
          <span>
            Quad Miners relocated the HQ office to Seonghong Tower in Yeoksam-dong, Seoul, paving the way for a greater development and growth.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=_csSwExOu8g" className="btn" target="_blank" onClick={click}>
            <FontAwesomeIcon icon={faPlayCircle} /> &nbsp;
            <span>Watch the Clip</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const TopTechCompany2020 = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2020.6</h4>
        <div className="wrapper has-image">
          <img src="/images/history/top_tech_2020.jpg" alt="APAC CIO Outlook Top 10 Cyber Security Solution Providers - 2019" />
        </div>
        <div className="wrapper-text">
          <span>
          Top Tech Company of 2020 - APAC BUSINESS Headlines
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://apacbusinessheadlines.com/Toptech-web-version/Quad-Miners/" className="btn" target="_blank">
            <FontAwesomeIcon icon={faNewspaper} /> &nbsp;
            <span>View Article</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const Sv2020 = () => {
  function click(e) {
    if($('body').width() >= 840) {
      e.preventDefault();
      ReactDOM.render(
        React.createElement(MovieHelper, {v:'6GOaEw84C_s'}),
        document.getElementById('Movie')
      );
    }
  }
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2020.7</h4>
        <div className="wrapper sv20201">
          <h2 className="hb-header">
            <div>19th Next Generation Security Vision 2020</div>
          </h2>
        </div>
        <div className="wrapper sv20202">
        </div>
        <div className="wrapper-text">
          <span>
          Quad Miners introduced Network Blackbox, which can comprehensively respond to evolving cyberattacks, at a conference introducing next-generation security products.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=6GOaEw84C_s" className="btn" target="_blank" onClick={click}>
            <FontAwesomeIcon icon={faPlayCircle} /> &nbsp;
            <span>Watch the Clip</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const Gartner = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2020.9</h4>
        <div className="wrapper has-image" style={{
          maxWidth: '180px'
        }}>
          <img src="/images/partners/gartner.png" alt="" />
        </div>
        <div className="wrapper-text">
          <span>
            2020 RELEASE "Quad Miners" named Network Detection and Response vendor in Gartner.
          </span>
          <br/>
          <small>
            Gartner, Emerging Technologies: Adoption Growth Insights for Network Detection and Response.
          </small>
        </div>
      </div>
    </section>
  )
}

const NusEnterprise = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2020.10</h4>
        <div className="wrapper has-image" style={{
          maxWidth: '400px'
        }}>
          <img src="/images/history/nus_enterprise_market_immersion_programme_2020.jpg" alt="NUS Enterprise Market Immersion Programme 2020" />
        </div>
        <div className="wrapper-text">
          <span>
          Quad Miners was selected as one of the 6 Korean start-up finalists, from a pool of 276 applicants, for the NUS Enterprise Market Immersion Programme 2020!
          </span>
          <br/>
          <small>
            NUS Enterprise is proud to partner with the Korea Institute of Startup and Entrepreneurship Development (KISED) to host this 8-week accelerator programme which will catalyze opportunities for these start-ups to commercialize solutions with local corporate partners and industry experts.
          </small>
        </div>
      </div>
    </section>
  )
}

const FirstKistiCouncil = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2020.11</h4>
        <div className="wrapper kisti1">
          <h2 className="hb-header">
            <div>2020 The 1st KISTI Council</div>
          </h2>
        </div>
        <div className="wrapper kisti2" />
        <div className="wrapper-text">
          <span>
          Quad Miners participated in "2020 The 1st KISTI Council" and introduced our innovative solution, Network Blackbox.
          </span>
        </div>
      </div>
    </section>
  )
}

const Company1000 = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2021.5</h4>
        <div className="wrapper has-image" style={{
          maxWidth: '380px'
        }}>
          <img src="/images/history/logo_ict_fsc.png" alt="" />
        </div>
        <div className="wrapper-text">
          <h4 style={{margin: 0}}>
          Quad Miners selected as the "National Innovative Company 1000" by the Financial Services Commission
          </h4>
          <div>
          Ministry of Science and ICT(MSIT) that collaborated with the Financial Services Commission evaluated the innovation, technology, and growth of the company and selected us as an innovator that will lead future innovation.
          </div>
        </div>
      </div>
    </section>
  )
}

const Top10CloudSolution = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2021.6</h4>
        <div className="wrapper has-image">
          <img src="/images/history/top10_cloud.png" alt="Top 10 Cloud Solution Providers - 2021 APAC CIO Outlook" />
        </div>
        <div className="wrapper-text">
          <span>
          Top 10 Cloud Solution Providers - 2021 APAC CIO Outlook
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://cloud.apacciooutlook.com/vendor/quad-miners-delivering-a-comprehensive-cloud-security-platform-cid-5866-mid-434.html" className="btn" target="_blank">
            <FontAwesomeIcon icon={faNewspaper} /> &nbsp;
            <span>View Article</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const Dupex2021 = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2021.10</h4>
        <div className="wrapper dupex1">
          <h2 className="hb-header">
            <div>DUPEX 2021</div>
          </h2>
        </div>
        <div className="wrapper dupex2" />
        <div className="wrapper-text">
          <span>
          Quad Miners Participate in Asia's largest defense exhibition! <br />
          'DUPEX KOREA 2021' is held at Seoul Airport from October 19th to 24th. <br />
          Quad Miners CTO (Felix) explains the proactive cyber threat-protection system. <br />
          He explains the limitations and risks of current security systems that rely on traditional detection and analysis based on limited data collection. 
          He suggests building an active cyber defense system to protect and respond to national facilities from advanced cyber threats.
          </span>
        </div>
      </div>
    </section>
  )
}

const Kodit2021 = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2021.10</h4>
        <div className="wrapper kodit1">
          <h2 className="hb-header">
            <div>
              Certificate of First-Penguin Startup
            </div>
          </h2>
        </div>
        <div className="wrapper-text">
        Quad Miners has been evaluated by the Korea Credit Guarantee Fund as a leading company 
        to lead the national economy\'s core growth engine industry.
        </div>
      </div>
    </section>
  )
}

const IglooMou = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2022.3</h4>
        <div className="wrapper igloo_mou">
          <h2 className="hb-header">
            <div>
            Quad Miners and Igloo Corporation <br/> 
            sign next-generation security control <br />
            technology development agreement
            </div>
          </h2>
        </div>
        <div className="wrapper-text">
        Quad Miners has signed a business agreement with Igloo Corporation, 
        a global information security company, 
        to develop next-generation security control technology.
        </div>
      </div>
    </section>
  )
}

const Gartner3 = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2022.4</h4>
        <div className="wrapper has-image" style={{
          maxWidth: '180px'
        }}>
          <img src="/images/partners/gartner.png" alt="" />
        </div>
        <div className="wrapper-text">
          <span>
          Quad Miners is a leading vendor listed in Network Detection and Response in Gartner reports for 3 consecutive year.
          </span>
        </div>
      </div>
    </section>
  )
}

const SeriesA = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2022.6</h4>
        <div className="wrapper series_a">
          <h2 className="hb-header"> </h2>
        </div>
        <div className="wrapper-text">
          <span>
          Quad Miners has raised over $6.5M in Series A funding.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=173371" className="btn" target="_blank">
            <FontAwesomeIcon icon={faNewspaper} /> &nbsp;
            <span>View Article</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const Gptw = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2022.8</h4>
        <div className="wrapper has-image">
          <img src="/images/history/gptw.jpg" alt="" />
        </div>
        <div className="wrapper-text">
          <span>
          Quad Miners has been certified as a Great Place to Work followed by being certified as a family-friendly environment.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.greatplacetowork.co.kr/kr/certification/certified-companies/quadminers" className="btn" target="_blank">
            <span>Learn more</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const Won35uskorea = () => {
  return(
    <section className="history-box">
      <div className="container">
        <h4 className="date">2022.8</h4>
        <div className="wrapper ukc20221">
          <h2 className="hb-header">
            <div>
              UKC 2022 Washington D.C.
            </div>
          </h2>
        </div>
        <div className="wrapper ukc20222">
        </div>
        <div className="wrapper-text">
        Quad Miners has won 1st Place in the US-Korea Conference 2022 Startup Pitch Competition.
        </div>
        <a rel="noreferrer noopener" href="https://ukc.ksea.org/ukc2022/" className="btn" target="_blank">
            <span>Learn more</span>
          </a>
      </div>
    </section>
  )
}

const Forbes100 = () => {
  return(
    <section className="history-box">
      <div className="container">
        <h4 className="date">2022.8</h4>
        <div className="wrapper has-image">
          <img src="/images/history/forbes_100_asia.png" alt="" />
        </div>
        <div className="wrapper-text">
          <span>
          Forbes named Quad Miners one of the top 100 Asian companies in 2022.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.forbes.com/sites/forbesasiateam/2022/08/29/forbes-asia-100-to-watch-2022/?sh=737c5311df33" className="btn" target="_blank">
            <span>Learn more</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const BabyUnicorn = () => {
  return(
    <section className="history-box">
      <div className="container">
        <h4 className="date">2022.10</h4>
        <div className="wrapper has-image">
          <img src="/images/history/unicorn.jpeg" alt="" />
        </div>
        <div className="wrapper-text">
          <span>
          The target of the Baby Unicorn 200 Promotion Project is a company that is expected to grow explosively in the future as it has competitive products or services ahead of completion of R&D. Quadminer was finally selected as one of 41 companies in the Baby Unicorn 200.
          </span>
          <br/>
        </div>
      </div>
    </section>
  )
}

const ICTExpoInJapan = () => {
  return(
    <section className="history-box">
      <div className="container">
        <h4 className="date">2022.12</h4>
        <div className="wrapper ictexpojapan1">
          <h2 className="hb-header">
            <div>
              2022 ICT Expo in Japan
            </div>
          </h2>
        </div>
        <div className="wrapper ictexpojapan2" />
        <div className="wrapper-text">
          <span>
            Quad Miners has signed 2 billion yen supply contract with Japan's Sateraito Office and LIONICE at the ICT Expo in Japan.
          </span>
          <br/>
        </div>
      </div>
    </section>
  )
}

const Gptw2023 = () => {
  function click(e) {
    if($('body').width() >= 840) {
      e.preventDefault();
      ReactDOM.render(
        React.createElement(MovieHelper, {v:'ZLJxhez2Oxg'}),
        document.getElementById('Movie')
      );
    }
  }
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2023.02</h4>
        <div className="wrapper has-image">
          <img src="/images/history/2023_best_workplaces_korea.jpg" alt="" />
        </div>
        <div className="wrapper-text">
          <span>
            Quad Miners is making a lot of effort to create a good workplace. They have received various awards such as the Korean GPTW Innovation Leader Award, 13th place in the 21st Korea Best Workplaces, and the Best Workplace for Working Mothers in Korea.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=1G50pp-9ADU" className="btn" target="_blank" onClick={click}>
            <FontAwesomeIcon icon={faPlayCircle} /> &nbsp;
            <span>Watch the Clip</span>
          </a>
        </div>
      </div>
    </section>
  )
};

const Piolink = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2023.03</h4>
        <div className="wrapper piolink1">
          <h2 className="hb-header">
            <div>
              The signing ceremony for PIOLINK's distributorship contract
            </div>
          </h2>
        </div>
        <div className="wrapper piolink2" />
        <div className="wrapper-text">
          <span>
            Quad Miners has signed a distributorship contract with PIOLINK, a specialized company in network and security services, to sell and provide technical support and services for NDR solutions.
          </span>
          <br/>
        </div>
      </div>
    </section>
  )
}

const Gartner4 = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2023.4</h4>
        <div className="wrapper has-image" style={{
          maxWidth: '180px'
        }}>
          <img src="/images/partners/gartner.png" alt="" />
        </div>
        <div className="wrapper-text">
          <span>
            Quad Miners has been listed as a leading NDR vendor for 4 consecutive years in Gartner's report on "Emerging Technologies: Adoption Growth Insights for Network Detection and Response"
          </span>
        </div>
      </div>
    </section>
  )
}

const PiolinkZeroTrust = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2023.08</h4>
        <div className="wrapper piolink_mou1">
          <h2 className="hb-header">
            <div>
              Quad Miners and Piolink have entered into a Network Zero Trust Security Work Agreement.
            </div>
          </h2>
        </div>
        <div className="wrapper piolink_mou2" />
        <div className="wrapper-text">
          <span>
            Quad Miners and Piolink have agreed to collaborate on the joint development of technology for implementing zero trust security within corporate intranets, leveraging the network security expertise and big data analytics capabilities possessed by both parties.
          </span>
          <br/>
        </div>
      </div>
    </section>
  )
}

const SeriesB = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2023.10</h4>
        <div className="wrapper series_b">
          <h2 className="hb-header"> </h2>
        </div>
        <div className="wrapper-text">
          <span>
            Quad Miners has raised over $11M in Series B funding.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.datanet.co.kr/news/articleView.html?idxno=188241" className="btn" target="_blank" >
            <FontAwesomeIcon icon={faNewspaper} /> &nbsp;
            <span>View Article</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const InnovationAward = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2023.12</h4>
        <div className="wrapper innovation_award">
          <h2 className="hb-header"> </h2>
        </div>
        <div className="wrapper-text">
          <span>
            Quad Miners received the Minister's Award at the Korea Innovation Award for Information Security Products, hosted by the Ministry of Science, ICT, and Future Planning of South Korea.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.etnews.com/20231206000143" className="btn" target="_blank" >
            <FontAwesomeIcon icon={faNewspaper} /> &nbsp;
            <span>View Article</span>
          </a>
        </div>
      </div>
    </section>
  )
}

const InsungDigital = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2024.2</h4>
        <div className="wrapper insung_digital">
          <h2 className="hb-header"> </h2>
        </div>
        <div className="wrapper-text">
          <span>
            Quad Miners has signed a distribution agreement with Insung Digital, a specialized technology distribution company, for next-generation network security solutions.
          </span>
        </div>
      </div>
    </section>
  )
}

const Gptw2024 = () => {
  function click(e) {
    if($('body').width() >= 840) {
      e.preventDefault();
      ReactDOM.render(
        React.createElement(MovieHelper, {v:'iYMfO3f_9pU'}),
        document.getElementById('Movie')
      );
    }
  }
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2024.02</h4>
        <div className="wrapper gptw2024_1">
          <h2 className="hb-header">
            <div>
              Selected as a Great Place to Work for, consecutively for 2 years
            </div>
          </h2>
        </div>
        <div className="wrapper gptw2024_2" />
        <div className="wrapper-text">
          <span>
            Quad Miners has been selected as one of the Top 100 Best Companies to Work for in South Korea in 2024 by GPTW. Additionally, they have obtained global ESG human rights management certification. Furthermore, the co-CEOs, Bumjoong Park and Jaewan Hong, have been recognized as the most respected CEOs in South Korea.
          </span>
          <br/>
          <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=iYMfO3f_9pU" className="btn" target="_blank" onClick={click}>
            <FontAwesomeIcon icon={faPlayCircle} /> &nbsp;
            <span>Watch the Clip</span>
          </a>
        </div>
      </div>
    </section>
  )
};

const KGlobal2023 = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2024.4</h4>
        <div className="wrapper has-image" style={{
          maxWidth: '190px'
        }}>
          <img src="/images/history/logo_ict.png" alt="" />
        </div>
        <div className="wrapper-text">
          <span>
            Quad Miners has been selected as a leading success case company in the overseas expansion sector of the K-Global Project conducted by the Ministry of Science and ICT.
          </span>
        </div>
      </div>
    </section>
  )
}

const InnovationIcon = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2024.05</h4>
        <div className="wrapper innovationicon">
          <h2 className="hb-header">
            <div>
              The 11th Innovation Icon
            </div>
          </h2>
        </div>
        <div className="wrapper-text">
          <span>
            The Korea Credit Guarantee Fund's “Innovation Icon Support Program” is a scale-up program directly supported by the Korea Credit Guarantee Fund by selecting startups that are expected to grow into unicorn companies.
          </span>
        </div>
      </div>
    </section>
  )
}

const JinnHyunAISperaMou = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2024.06</h4>
        <div className="wrapper jinaimou">
          <h2 className="hb-header">
            <div>
              Jin&amp;Hyun Security, Quad Miners, and AI SPERA Sign Memorandum of Understanding (MoU) for Mutual Cooperation
            </div>
          </h2>
        </div>
        <div className="wrapper-text">
          <span>
            The three cybersecurity companies have agreed to combine their respective strengths and engage in close cooperation and exchange, in order to provide advanced technologies and enhanced services in the cybersecurity market, based on their collaborative partnership.
          </span>
        </div>
      </div>
    </section>
  )
}

const DefenseSecurityConference19 = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2024.07</h4>
        <div className="wrapper defensesecurityconference19_1">
          <h2 className="hb-header">
            <div>
              Information security exhibition of the 19th defense security conference
            </div>
          </h2>
        </div>
        <div className="wrapper defensesecurityconference19_2" />
        <div className="wrapper-text">
          <span>
            Quad Miners participated in the National Defense Security Conference hosted by the Ministry of National Defense and organized by the Defense Security Command, and showcased its technological capabilities to address the K-defense industry and K-security sectors.
          </span>
        </div>
      </div>
    </section>
  )
}

const WenyoutechMou = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2024.07</h4>
        <div className="wrapper wenyoutech" />
        <div className="wrapper-text">
          <span>
            Quad Miners has signed a Memorandum of Understanding with WE n YOU TECH to jointly develop and expand their SIEM business. Through the exchange of their respective technologies, the two companies aim to grow their business operations.
          </span>
        </div>
      </div>
    </section>
  )
}

const CyberDSA2024 = () => {
  return (
    <section className="history-box">
      <div className="container">
        <h4 className="date">2024.08</h4>
        <div className="wrapper cyberdsa2024_1">
          <h2 className="hb-header">
            <div>
              Cyber DSA 2024
            </div>
          </h2>
        </div>
        <div className="wrapper cyberdsa2024_2" />
        <div className="wrapper-text">
          <span>
            Quad Miners attended CyberDSA in Kuala Lumpur, where they engaged with Cybersecurity and Defense professionals.
          </span>
        </div>
      </div>
    </section>
  )
}

export default History;