const Menu = {
  ko: [
    {
      title: "솔루션",
      menu: [
        {
          title: "Network Blackbox",
          rt: "Blackbox",
          href: "/ko/network_blackbox.html",
        },
        // {title:'FP NIDS', rt: 'FPNIDS', href: '/ko/fp_nids.html'},
        {
          title: "Insider Threat",
          rt: "InsiderThreat",
          href: "/ko/insider_threat.html",
        },
        {
          title: "Cloud Blackbox",
          rt: "CloudBlackbox",
          href: "/ko/cloud_blackbox.html",
        },
        { title: "QUADX", rt: "QuadX", href: "/ko/quadx.html" },
      ],
    },
    {
      title: "비즈니스",
      menu: [
        { title: "구축사례", rt: "CaseStudy", href: "/ko/case_studies.html" },
        { title: "파트너", rt: "Partners", href: "/ko/partners.html" },
        { title: "Contact Us", rt: "ContactUs", href: "/ko/contact_us.html" },
      ],
    },
    {
      title: "쿼드마이너",
      menu: [
        { title: "회사소개", rt: "About", href: "/ko/about.html" },
        { title: "연혁", rt: "History", href: "/ko/history.html" },
        { title: "Press", rt: "Press", href: "/ko/press.html" },
        { title: "채용안내", rt: "Career", href: "/ko/career.html" },
      ],
    },
  ],
  en: [
    {
      title: "Products",
      menu: [
        {
          title: "Network Blackbox",
          rt: "Blackbox",
          href: "/en/network_blackbox.html",
        },
        // {title:'FP NIDS', rt: 'FPNIDS', href: '/en/fp_nids.html'},
        {
          title: "Insider Threat",
          rt: "InsiderThreat",
          href: "/en/insider_threat.html",
        },
        {
          title: "Cloud Blackbox",
          rt: "CloudBlackbox",
          href: "/en/cloud_blackbox.html",
        },
        { title: "QUADX", rt: "QuadX", href: "/en/quadx.html" },
      ],
    },
    {
      title: "For Business",
      menu: [
        {
          title: "Case Studies",
          rt: "CaseStudy",
          href: "/en/case_studies.html",
        },
        { title: "Partners", rt: "Partners", href: "/en/partners.html" },
        { title: "Contact Us", rt: "ContactUs", href: "/en/contact_us.html" },
      ],
    },
    {
      title: "About Quad Miners",
      menu: [
        { title: "Company", rt: "About", href: "/en/about.html" },
        { title: "History", rt: "History", href: "/en/history.html" },
        { title: "Careers", rt: "Career", href: "/en/career.html" },
      ],
    },
  ],
};

export default Menu;
