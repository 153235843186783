import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import Menu from "./Menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faBars,
  faChevronUp,
} from "@fortawesome/pro-light-svg-icons";
import { faMessageQuestion } from "@fortawesome/pro-duotone-svg-icons";

const Head = ({ active, lang }) => {
  const [menu, setMenu] = useState(false);
  const toggle = (e) => {
    setMenu(!menu);
    e.preventDefault();
  };
  let locationHook = useLocation();
  locationHook = locationHook.pathname;

  function click(e) {
    // let lang = $(e.target).attr('data-lang') || 'ko';
    // navigate( window.location.pathname.replace(/^.{3}/, `/${lang}`) );
    let lang = $(e.target).attr("data-lang") || "ko";
    localStorage && localStorage.setItem("lang", lang);
    let urlLang = locationHook.replace(/^.{3}/, `/${lang}`);
    urlLang === "/en/press.html"
      ? $(e.target).attr("href", "/en/index.html")
      : $(e.target).attr("href", urlLang);
  }
  return (
    <header className={`header ${menu ? "show" : ""}`} style={{}}>
      <div className={`container`}>
        <h1>
          <Link to={`/${lang}/index.html`}>
            <span className="sr-only">Quad Miners</span>
            <img
              src="/images/common/logo_line_white.png"
              alt="Quad Miners logo"
            />
          </Link>
        </h1>
        <nav>
          <ul>
            {Menu[lang].map((d) => (
              <li key={d.title}>
                <span onClick={toggle}>
                  {d.title} <FontAwesomeIcon icon={faAngleDown} />
                </span>
              </li>
            ))}
          </ul>
        </nav>
        <div className="helpdesk">
          <Link
            to={`/${lang}/help_desk.html`}
            className={`${active === "HelpDesk" ? "active" : ""}`}
          >
            <span>
              <FontAwesomeIcon
                icon={faMessageQuestion}
                style={{
                  color: "#fff",
                }}
              />
              &nbsp; Help Desk
            </span>
          </Link>
        </div>
        <div className="mobile-helper">
          <button onClick={toggle}>
            <span className="open">
              <FontAwesomeIcon icon={faBars} />
            </span>
            <span className="close">
              <FontAwesomeIcon icon={faChevronUp} />
            </span>
          </button>
        </div>
      </div>
      <div className="menusWrapper">
        <div className="menus">
          {Menu[lang].map((d, i1) => (
            <div className="menu" key={i1}>
              <ul>
                {d.menu.map((a, i2) => (
                  <li key={i2}>
                    <Link
                      to={a.href}
                      className={`${active === a.rt ? "active" : ""}`}
                    >
                      {a.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          <div className="language">
            <span>Languages</span>
            <div className="menu">
              <ul>
                <li>
                  <a
                    href=" "
                    style={
                      lang === "en" ? { color: "#228ae6" } : { color: "#fff" }
                    }
                    onClick={click}
                    data-lang="en"
                  >
                    English
                  </a>
                </li>
                <li>
                  <a
                    href=" "
                    style={
                      lang === "ko" ? { color: "#228ae6" } : { color: "#fff" }
                    }
                    onClick={click}
                    data-lang="ko"
                  >
                    한국어
                  </a>
                </li>
                <li>
                  <a href="https://quadminers.co.jp">日本語</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="helpdesk-for-mobile">
            <Link
              to={`/${lang}/help_desk.html`}
              className={`${active === "HelpDesk" ? "active" : ""}`}
            >
              <span>
                <FontAwesomeIcon
                  icon={faMessageQuestion}
                  style={{
                    color: "#fff",
                  }}
                />
                &nbsp; Help Desk
              </span>
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Head;
